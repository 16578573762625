<script>
// import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
// import PageHeader from "@/components/page-header";
// import axios from "axios";
import Swal from "sweetalert2";
import HeaderAsesmenHC from "../header_asesmen-hc.vue";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    HeaderAsesmenHC,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Default",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 1,
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fa-cc-visa", "Visa"],
          index: 2,
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3,
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 4,
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fa-cc-visa", "Visa"],
          index: 5,
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6,
        },
      ],
      modalProgress: false,
    };
  },
  methods: {
    selesai() {
      Swal.fire({
        icon: "warning",
        title: "Konfirmasi!",
        text: "Apakah Anda Yakin?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Ya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "success",
            title: "Selesai!",
            text: "Jawaban berhasil di simpan",
            timer: 2000,
            timerProgressBar: true,
          });
          this.modalProgress = false;
        } else {
          this.modalProgress = false;
        }
      });
    },
  },
  // mounted() {
  //   let self = this;
  //   self.cekAksesMenu();
  // },
  // methods: {
  //   cekAksesMenu() {
  //     let self = this;
  //     var config_axios = {
  //       method: "get",
  //       url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
  //       params: {
  //         role_id: self.sessionRoleId,
  //         link_name: self.namePathUrl,
  //       },
  //       headers: {
  //         Accept: "application/json",
  //         Authorization: "Bearer " + localStorage.access_token_peserta,
  //       },
  //     };
  //     axios(config_axios)
  //       .then((response) => {
  //         let response_data = response.data;
  //         let response_data_fix = response_data.data.status_access;
  //         if (response_data.meta.code == 200) {
  //           if (response_data_fix == false) {
  //             let timerInterval;
  //             Swal.fire({
  //               icon: "warning",
  //               title: "Oppss",
  //               text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
  //               timer: 2000,
  //               timerProgressBar: true,
  //               showCancelButton: false,
  //               showConfirmButton: false,
  //               didOpen: () => {
  //                 timerInterval = setInterval(() => {
  //                   const content = Swal.getContent();
  //                   if (content) {
  //                     const b = content.querySelector("b");
  //                     if (b) {
  //                       b.textContent = Swal.getTimerLeft();
  //                     }
  //                   }
  //                 }, 100);
  //               },
  //               willClose: () => {
  //                 clearInterval(timerInterval);
  //               },
  //             }).then((result) => {
  //               /* Read more about handling dismissals below */
  //               if (result.dismiss === Swal.DismissReason.timer) {
  //                 self.$router.push({ name: "dashboard" });
  //               }
  //             });
  //           }
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: response_data_fix.data.message,
  //           });
  //         }
  //       })
  //       .catch((errors) => {
  //         console.log(errors);
  //       });
  //   },
  // },
};
</script>

<template>
  <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
  <div class="container">
    <HeaderAsesmenHC />
    <div class="card">
      <div class="card-header bg-primary text-white font-size-16">
        <div class="row">
          <div class="col-xl-10">Uji Kompetensi : xxxx xxxx xxxx</div>
          <div class="col-xl-2">
            <button
              type="button"
              class="btn btn-info float-end"
              @click="modalProgress = true"
            >
              Lihat Progress
            </button>
            <b-modal
              v-model="modalProgress"
              title="Progress Pengisian Soal"
              hide-footer
            >
              <div class="badge bg-danger form-control font-size-16 pt-4 pb-4">
                Masih ada soal yang belum jawab
              </div>
              <div class="row mt-4">
                <div class="col-xl">
                  <div class="badge bg-success font-size-16 form-control p-3">
                    1
                  </div>
                </div>
                <div class="col-xl">
                  <div class="badge bg-success font-size-16 form-control p-3">
                    2
                  </div>
                </div>
                <div class="col-xl">
                  <div class="badge bg-success font-size-16 form-control p-3">
                    3
                  </div>
                </div>
                <div class="col-xl">
                  <div class="badge bg-success font-size-16 form-control p-3">
                    4
                  </div>
                </div>
                <div class="col-xl">
                  <div class="badge bg-danger font-size-16 form-control p-3">
                    5
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-xl">
                  <div class="badge bg-success font-size-16 form-control p-3">
                    6
                  </div>
                </div>
                <div class="col-xl">
                  <div class="badge bg-success font-size-16 form-control p-3">
                    7
                  </div>
                </div>
                <div class="col-xl">
                  <div class="badge bg-success font-size-16 form-control p-3">
                    8
                  </div>
                </div>
                <div class="col-xl">
                  <div class="badge bg-success font-size-16 form-control p-3">
                    9
                  </div>
                </div>
                <div class="col-xl">
                  <div class="badge bg-success font-size-16 form-control p-3">
                    10
                  </div>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
      <div class="card-body font-size-14">
        <div class="bg-light">
          <p class="p-2 mb-0">
            Narasi tentang soal ini Narasi tentang soal ini Narasi tentang soal
            ini Narasi tentang soal ini Narasi tentang soal ini Narasi tentang
            soal ini Narasi tentang soal ini Narasi tentang soal iniNarasi
            tentang soal ini.
          </p>
        </div>
        <table class="table">
          <tbody class="border-none">
            <tr>
              <td>1.</td>
              <td>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Praesentium libero molestias ex sint perferendis qui a
                  delectus excepturi! Rerum laboriosam distinctio assumenda
                  sequi! Quo provident minima in esse laboriosam! Voluptatem.
                </p>
                <button type="button" class="btn btn-primary">Pilihan 1</button>
                &nbsp;
                <button type="button" class="btn btn-primary">Pilihan 2</button>
              </td>
            </tr>
            <tr>
              <td>2.</td>
              <td>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Praesentium libero molestias ex sint perferendis qui a
                  delectus excepturi! Rerum laboriosam distinctio assumenda
                  sequi! Quo provident minima in esse laboriosam! Voluptatem.
                </p>
                <button type="button" class="btn btn-primary">Pilihan 1</button>
                &nbsp;
                <button type="button" class="btn btn-primary">Pilihan 2</button>
              </td>
            </tr>
            <tr>
              <td>3.</td>
              <td>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Praesentium libero molestias ex sint perferendis qui a
                  delectus excepturi! Rerum laboriosam distinctio assumenda
                  sequi! Quo provident minima in esse laboriosam! Voluptatem.
                </p>
                <button type="button" class="btn btn-primary">Pilihan 1</button>
                &nbsp;
                <button type="button" class="btn btn-primary">Pilihan 2</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-warning float-end mb-2"
      @click="selesai()"
    >
      Selesai
    </button>
  </div>
  <!-- end row -->
  <!-- </Layout> -->
</template>
<style scoped>
.border-none {
  border-style: hidden;
}
</style>